import { DateTime } from 'luxon'
import type { ComputedRef, DeepReadonly, Ref } from 'vue'
import type { Meeting } from '@/entities/meeting'
import type { UseUserState } from './useUser'

export interface CreateMeetingParams {
  companyId: number
  title?: string | null
  is_caption_enabled?: boolean
  is_audio_video_enabled?: boolean
  deck_id?: number | null
  description?: string | null
  scheduled_start_at?: string | null
  scheduled_end_at?: string | null
}

export interface CreateRecordingParams {
  companyId: number
  title: string
  deckId: number
}

export interface UpdateMeetingParams {
  share_id?: string
  title?: string | null
  description?: string | null
  scheduled_start_at?: string | null
  scheduled_end_at?: string | null
}

export interface UseMeetingsState {
  loading: boolean
  error: Error | null
  meetings: Meeting[]
  pastMeetings: Meeting[]
}

export interface UseMeetings {
  state: DeepReadonly<Ref<UseMeetingsState>>
  firstActiveMeeting: ComputedRef<Meeting | null>
  fetchMeetings: () => Promise<void>
  createMeeting: (params: CreateMeetingParams) => Promise<Meeting>
  createRecording: (params: CreateRecordingParams) => Promise<Meeting>
  updateMeeting: (params: UpdateMeetingParams) => Promise<Meeting>
  cancelMeeting: (params: DeepReadonly<Meeting>) => Promise<void>
}

export function useMeetings({
  userState,
  setSelectedCompany,
}: {
  userState: DeepReadonly<Ref<UseUserState>>
  setSelectedCompany(companyId: number): void
}): UseMeetings {
  const state = useState<UseMeetingsState>('meetings', () => ({
    loading: false,
    error: null,
    meetings: [],
    pastMeetings: [],
  }))

  const firstActiveMeeting = computed<Meeting | null>(() => {
    return state.value.meetings.find(_isMeetingScheduledForRightNow) || null
  })

  async function fetchMeetings() {
    if (!userState.value.user) {
      state.value = { ...state.value, meetings: [], pastMeetings: [], error: null }
      return
    }

    state.value = { ...state.value, error: null, loading: true }

    try {
      const [upcoming, past] = await Promise.all([
        $fetch<{ results: Meeting[] }>('v2/conferences', {
          params: {
            include: ['company', 'host'],
            scheduled_end_at__gte: DateTime.local().toISO(),
            sort_by: 'scheduled_start_at',
          },
          ...useFetchSharedOptions(),
        }),
        $fetch<{ results: Meeting[] }>('v2/conferences', {
          params: {
            include: ['company', 'host'],
            scheduled_end_at__gte: DateTime.local().minus({ month: 1 }).toISO(),
            scheduled_end_at__lt: DateTime.local().toISO(),
            sort_by: '-scheduled_start_at',
            limit: 6,
          },
          ...useFetchSharedOptions(),
        }),
      ])

      state.value = { ...state.value, meetings: upcoming.results, pastMeetings: past.results }
    } catch (e) {
      if (e instanceof Error) {
        state.value = { ...state.value, error: e }
      }
    } finally {
      state.value = { ...state.value, loading: false }
    }
  }

  async function createMeeting({ companyId, ...params }: CreateMeetingParams): Promise<Meeting> {
    try {
      const data = await $fetch<Meeting>(`v2/companies/${companyId}/conferences`, {
        method: 'POST',
        body: {
          is_caption_enabled: false,
          ...params,
        },
        ...useFetchSharedOptions(),
      })

      setSelectedCompany(companyId)

      return data
    } catch (e) {
      throw e
    }
  }

  async function createRecording({ title, companyId, deckId }: CreateRecordingParams): Promise<Meeting> {
    try {
      const data = await $fetch<Meeting>(`v2/companies/${companyId}/conferences`, {
        ...useFetchSharedOptions(),
        method: 'POST',
        body: {
          title,
          is_caption_enabled: true,
          is_audio_video_enabled: true,
          deck_id: deckId,
        },
      })

      setSelectedCompany(companyId)

      return data
    } catch (e) {
      throw e
    }
  }

  async function updateMeeting({ share_id, ...params }: UpdateMeetingParams): Promise<Meeting> {
    if (!share_id) throw new Error('share_id is missing')

    try {
      const data = await $fetch<Meeting>(`v2/conferences/${share_id}`, {
        method: 'PATCH',
        body: params,
        ...useFetchSharedOptions(),
      })

      return data
    } catch (e) {
      throw e
    }
  }

  async function cancelMeeting(meeting: DeepReadonly<Meeting>): Promise<void> {
    return $fetch(`v2/companies/${meeting.company_id}/conferences/${meeting.share_id}`, {
      method: 'DELETE',
      ...useFetchSharedOptions(),
    })
  }

  watch(() => userState.value.user, fetchMeetings, { immediate: true })

  function _isMeetingScheduledForRightNow(meeting: Meeting): boolean {
    const scheduledStartTime = DateTime.fromISO(meeting.scheduled_start_at ?? '')
    const scheduledEndTime = DateTime.fromISO(meeting.scheduled_end_at ?? '')

    return scheduledStartTime < DateTime.local() && scheduledEndTime > DateTime.local()
  }

  return {
    state: readonly(state),
    firstActiveMeeting,
    fetchMeetings,
    createMeeting,
    createRecording,
    updateMeeting,
    cancelMeeting,
  }
}
